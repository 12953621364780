import React from 'react';
import { motion } from 'framer-motion';

import { IconWrapper, InnerIcon } from './Content.style';

const Icon = ({ icon, direction }) => {
  const pathVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
      pathLength: 0,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    },
  };

  const iconVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 0.3 },
    },
  };

  return (
    <IconWrapper direction={direction}>
      <svg className='circle'>
        <motion.path
          variants={pathVariants}
          initial='hidden'
          animate='visible'
          exit='exit'
          d='M0,50a50,50 0 1,0 100,0a50,50 0 1,0 -100,0'
          fill='none'
          stroke='#fff'
        />
      </svg>
      <InnerIcon direction={direction}>
        <motion.div
          variants={iconVariants}
          initial='hidden'
          animate='visible'
          exit='exit'
        >
          {icon}
        </motion.div>
      </InnerIcon>
    </IconWrapper>
  );
};

export default Icon;
