import styled from 'styled-components';

export const ContactWrapper = styled.div`
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 20;
  text-align: center;

  @media (max-width: 768px) {
    display: block;
    top: 20%;
    translate: transformY(-20%);
  }

  .social-list {
    margin-right: 20px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @media (max-width: 768px) {
        padding-bottom: 20px;
      }
      padding-bottom: 50px;
      display: inline-block;
    }

    .icon {
      transition-duration: 0.3s;
      width: 25px;
      cursor: pointer;
    }

    .github {
      fill: #000;
      margin-right: 15px;

      :hover {
        transition-duration: 0.3s;
        fill: #181717;
      }
    }

    .linkedin {
      fill: #000;
      margin-right: 15px;

      :hover {
        transition-duration: 0.3s;
        fill: #0077b5;
      }
    }

    .upwork {
      fill: #000;
      :hover {
        transition-duration: 0.3s;
        fill: #6fda44;
      }
    }
  }

  .contactContainer {
    display: flex;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const ContactParticleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 80%;

  @media (max-width: 768px) {
    height: 60%;
  }

  img {
    display: none;
  }
`;

export const ContactCol = styled.div`
  padding: 50px;
  max-width: 300px;

  @media (max-width: 768px) {
    h1 {
      font-size: 25px;
    }
  }

  .right {
    color: #197a8c;
  }
`;
