import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import useOnClickOutside from 'helpers/useOnClickOutside';
import { Navigation, LinkedInIcon, GitHubIcon } from 'components';
import { MenuWrapper } from '../Layout.style';

const Menu = ({ open, toggle, setIsOpen, pathname }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const runFadeAnimation = () => {
    const fadeEvent = new Event('fadeEvent');
    window.dispatchEvent(fadeEvent);
  };

  return (
    <>
      <MenuWrapper ref={ref}>
        <motion.div
          animate={{ x: open ? 365 : 0, opacity: open ? 1 : 0 }}
          transition={{ ease: [0.23, 1, 0.32, 1], duration: 1 }}
          initial={false}
        >
          <div className='nav-content'>
            <div className='nav-items'>
              <ul className='nav-list'>
                <li>
                  <Link
                    to='/'
                    onClick={() => {
                      toggle();

                      if (pathname !== '/') {
                        runFadeAnimation();
                      }
                    }}
                  >
                    <span>
                      Home
                      <sup>00</sup>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/benefits/1'
                    onClick={() => {
                      toggle();

                      if (pathname !== '/benefits/1') {
                        runFadeAnimation();
                      }
                    }}
                  >
                    <span>
                      Benefits
                      <sup>01</sup>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/services/1'
                    onClick={() => {
                      toggle();

                      if (pathname !== '/services/1') {
                        runFadeAnimation();
                      }
                    }}
                  >
                    <span>
                      Services
                      <sup>02</sup>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/projects'
                    onClick={() => {
                      toggle();
                      runFadeAnimation();
                    }}
                  >
                    <span>
                      Projects
                      <sup>03</sup>
                    </span>
                  </Link>
                </li>
                <li className='contact'>
                  <Link
                    to='/contact'
                    onClick={() => {
                      toggle();
                      runFadeAnimation();
                    }}
                  >
                    <span>
                      Contact
                      <sup>04</sup>
                    </span>
                  </Link>
                </li>
              </ul>
              <ul className='social-list'>
                <li>
                  <GitHubIcon />
                </li>
                <li>
                  <LinkedInIcon />
                </li>
              </ul>
            </div>
          </div>
        </motion.div>
        {open ? null : <Navigation />}
      </MenuWrapper>
    </>
  );
};

export default Menu;
