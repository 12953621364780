import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { LogoWrapper } from '../Layout.style';

const Logo = ({ setIsOpen, isOpen, width, isContactPage }) => {
  const { pathname } = useLocation();

  const open = () => {
    if (width < 768 && !isOpen) {
      return true;
    } else if (pathname !== '/' && width < 768 && !isOpen) {
      return true;
    } else if (pathname !== '/' && width > 767) {
      return true;
    }
  };

  return (
    <LogoWrapper>
      {open() ? (
        <>
          {isContactPage ? (
            <motion.div
              animate={{ x: -100 }}
              transition={{ cubicBezier: [0.23, 1, 0.32, 1], duration: 1 }}
            >
              <Link
                to='/'
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <h2 className='logo-inner black'>hyperse</h2>
              </Link>
            </motion.div>
          ) : (
            <motion.div
              animate={{ x: -100 }}
              transition={{ cubicBezier: [0.23, 1, 0.32, 1], duration: 1 }}
            >
              <Link
                to='/'
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <h2 className='logo-inner'>hyperse</h2>
              </Link>
            </motion.div>
          )}
        </>
      ) : null}
    </LogoWrapper>
  );
};

export default Logo;
