import React from 'react';
import { motion } from 'framer-motion';

const ActiveLine = ({ position }) => {
  return (
    <motion.span
      animate={{ y: position.y, scaleY: position.scale }}
      transition={{ cubicBezier: [0.23, 1, 0.32, 1], duration: 1 }}
      className='active-line'
    ></motion.span>
  );
};

export default ActiveLine;
