const projects = [
  {
    title: 'Project 1',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, harum at rerum quam.',
    link: '#',
  },
  {
    title: 'Project 2',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, harum at rerum quam.',
    link: '#',
  },
  {
    title: 'Project 3',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, harum at rerum quam.',
    link: '#',
  },
];

export default projects;
