import styled from 'styled-components';

export const Timeline = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  padding-top: 90px;
  padding-bottom: 70px;
  z-index: 20;
`;

export const Navline = styled.div`
  opacity: 0.3;
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  margin-left: 70px;
  z-index: 20;
  :before {
    z-index: 20;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    margin-left: -1px;
    background: ${({ isContactPage }) =>
      !isContactPage ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.5)'};
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;

  li {
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
    height: calc(100% / 6);
    z-index: 20;
    :after {
      content: '';
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }

  .moveableList {
    transform: translateX(75px);
  }

  a {
    position: relative;
    width: 70px;
    text-align: right;
    padding-right: 20px;
    padding-left: 25px;
    text-decoration: none;
    color: ${({ isContactPage }) =>
      !isContactPage ? 'inherit' : 'rgba(0, 0, 0, 0.5)'};
    cursor: pointer;
    opacity: 0.3;
    transition-duration: 1s;
  }

  .sectionName {
    cursor: pointer;
    padding-left: 45px;
    opacity: 0;
    transition-duration: 1s;
  }

  a:hover {
    opacity: 1;

    transition-duration: 0.3s;
    span {
      opacity: 1 !important;
    }
  }

  .active {
    z-index: 1;
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);

    transform: translateX(0px);

    a {
      opacity: 1;
    }

    .sectionName {
      opacity: 1;
    }
  }

  ul {
    display: table;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const PointWrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  margin-left: 70px;
  z-index: 1;

  ul {
    position: relative;
    opacity: 1;
    height: calc(100% / 6);
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
  }

  ul.navlist {
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      width: 4px;
      margin-left: -2px;
      background: ${({ isContactPage }) =>
        !isContactPage ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
      border-radius: 50%;
    }
  }

  span.plus {
    :before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 1px;
      height: 7px;
      margin-left: -1px;
      background: ${({ isContactPage }) =>
        !isContactPage ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
    }

    :after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 7px;
      height: 1px;
      margin-left: -4px;
      background: ${({ isContactPage }) =>
        !isContactPage ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)'};
    }
  }

  li.listitem {
    height: calc(100% / 3);
  }

  span.point {
    display: block;
    height: 2px;
    width: 2px;
    margin-left: -1px;
    background: ${({ isContactPage }) =>
      !isContactPage ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.6)'};
    border-radius: 50%;
    z-index: 1;
  }

  .active-line {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% / 6);
    width: 3px;
    background: #197a8c;
    margin-left: -2px;
    margin-top: 1px;
    transform-origin: center top;
    box-shadow: ${({ isContactPage }) =>
      !isContactPage ? '0px 0px 12px #197a8c' : '0'};
  }
`;

export const MobileNavWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 80px;
  width: 100%;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);

  .mobile-content {
    margin-left: 20px;
    margin-bottom: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
  }

  .mobile-inner {
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 25px;

    svg {
      margin-top: 9px;
      margin-left: 1px;
    }

    a {
      padding: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      color: white;
      display: block;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;

      :before {
        box-sizing: border-box;
        transform: scale(1) translateZ(0);
        transition: background 0.4s cubic-bezier(0.23, 1, 0.32, 1);
        background: #197a8c;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 25px;
      }
    }
  }

  .mobile-arrows {
    margin-bottom: 15px;
    margin-right: 15px;
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;

    .page-prev {
      display: inline-block;
      vertical-align: middle;
      margin-top: 20px;
      margin-right: 10px;
      text-align: center;
      background: none;
      border-radius: 50%;
      padding: 0;
      height: 40px;
      width: 40px;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      border: none;
      outline: none;
    }

    .page-next {
      display: inline-block;
      vertical-align: middle;
      margin-top: 20px;
      text-align: center;
      background: none;
      border-radius: 50%;
      padding: 0;
      height: 40px;
      width: 40px;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      border: none;
      outline: none;
    }
  }
`;
