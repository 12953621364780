import React from 'react';
import { Content, benefitsIcon2 } from 'components';

export const Why2 = () => {
  const description =
    "Your Customer's needs change constantly. Qualitative and quantitative research will get you answers on how to adapt. By using the right Tools and Setup, you will get meaningful Insights on your Users' Behavior and set your priorities straight on the most impactful Projects.";

  return (
    <>
      <Content
        icon={benefitsIcon2}
        direction='right'
        title={
          <span>
            Approach your Customer's Needs with{' '}
            <span className='glow'>Digital Intelligence</span>
          </span>
        }
        description={description}
        imgSrc='/assets/img/image-why2.png'
        particles={5}
      />
    </>
  );
};
