import styled from 'styled-components';

export const ImageWrapper = styled.div`
  margin-left: ${({ direction }) => (direction === 'left' ? 40 : 10)}%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: ${({ direction }) => direction};
  width: 1000px;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  img {
    display: none;
  }

  .particlecontainer {
    z-index: 0;

    canvas {
      z-index: 0;
    }
  }

  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;

    bottom: 20%;
    transform: translateY(20%);
  }
`;
