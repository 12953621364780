import React from 'react';

import { Content, benefitsIcon3 } from 'components';

export const Why3 = () => {
  const description =
    "Websites don't have to be static. Make your Page come to life by using modern Web Technology to enthuse your Customers. Micro-Interactions and intuitive Design provide your Customers a better Understanding of your Product. Don't tell, show it.";

  return (
    <>
      <Content
        icon={benefitsIcon3}
        direction='left'
        title={
          <span>
            Setting a <span className='glow'>New Standard</span> in Web
            Experience
          </span>
        }
        description={description}
        imgSrc='/assets/img/image-why3.png'
        particles={3}
      />
    </>
  );
};
