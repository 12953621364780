import React from 'react';
import { useHistory } from 'react-router-dom';
import Particles from 'react-particles-js';
import { motion } from 'framer-motion';

import useWindowSize from 'helpers/useWindowSize';
import colorSwitcher from 'helpers/colorSwitcher';
import { ParticleBgWrapper } from '../Layout.style';

const ParticlesBackground = ({ isContactPage }) => {
  const { location } = useHistory();
  const { width } = useWindowSize();

  const isMobile = width < 768;

  return (
    <ParticleBgWrapper>
      <motion.div
        style={{ width: '100%', height: '100%' }}
        transition={{
          cubicBezier: [0.3, 0.1, 0.05, 1],
          duration: 2,
        }}
        initial={false}
        animate={{
          y: colorSwitcher(location.pathname, isMobile).yAxis,
        }}
      >
        <motion.div
          className='particlesBgWrapper'
          transition={{
            cubicBezier: [0.23, 1, 0.32, 1],
            duration: 1,
            delay: 1,
          }}
          initial={false}
          animate={{
            backgroundImage: colorSwitcher(location.pathname, isMobile).color,
          }}
        >
          <Particles
            style={{
              position: 'absolute',
              background: 'transparent',
            }}
            params={{
              particles: {
                number: {
                  value: 100,
                  density: { enable: true, value_area: 800 },
                },
                color: { value: isContactPage ? '#000000' : '#ffffff' },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                },
                opacity: {
                  value: 1,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0,
                    sync: false,
                  },
                },
                size: {
                  value: 0.5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 4,
                    size_min: 0.3,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: '#ffffff',
                  opacity: 0.05,
                  width: 0,
                },
                move: {
                  enable: true,
                  speed: 1,
                  direction: 'none',
                  random: true,
                  straight: false,
                  out_mode: 'out',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 600,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onHover: {
                    enable: false,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 263.73626373626377,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />
        </motion.div>
      </motion.div>
    </ParticleBgWrapper>
  );
};

export default ParticlesBackground;
