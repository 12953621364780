import React from 'react';
import { motion } from 'framer-motion';

import { ParticleImage } from 'components';
import { contentVariants } from 'constants/animationVariants';

import { HomeTextWrapper } from './Home.style';

export const Home = () => {
  return (
    <>
      <HomeTextWrapper>
        <motion.div
          className='div'
          variants={contentVariants}
          animate='in'
          initial='out'
          exit='exit'
        >
          <h1>
            Your <span className='glow'>Partner</span> in Web Technology
          </h1>
          <p style={{ marginBottom: '30px' }}>
            As your professional Partner we work closely together to deliver the
            most value for Your Goals and to develop top-notch, easy-to-use
            products that will enthuse you customers.
          </p>
        </motion.div>
      </HomeTextWrapper>
      <ParticleImage
        direction='left'
        imgSrc='/assets/img/image-home.png'
        particles={3}
      />
    </>
  );
};
