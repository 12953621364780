export const routeSwitcher = (currentPath) => {
  switch (currentPath) {
    case '/':
      return { prev: '/', next: '/benefits' };
    case '/benefits':
      return { prev: '/', next: '/benefits/2' };
    case '/benefits/2':
      return { prev: '/benefits', next: '/benefits/3' };
    case '/benefits/3':
      return { prev: '/benefits/2', next: '/services' };
    case '/services':
      return { prev: '/benefits/3', next: '/services/2' };
    case '/services/2':
      return { prev: '/services', next: '/services/3' };
    case '/services/3':
      return { prev: '/services/2', next: '/projects' };
    case '/projects':
      return { prev: '/services/3', next: '/contact' };
    case '/contact':
      return { prev: '/projects', next: '#' };
    default:
      return { prev: '/', next: '/' };
  }
};

export const currentPosition = (path) => {
  switch (path) {
    case '/benefits':
      return { y: window.innerHeight / 6, scale: 0.3333 };
    case '/benefits/2':
      return { y: window.innerHeight / 4.5, scale: 0.3333 };
    case '/benefits/3':
      return { y: window.innerHeight / 3.6, scale: 0.3333 };
    case '/services':
      return { y: window.innerHeight / 3, scale: 0.3333 };
    case '/services/2':
      return { y: window.innerHeight / 2.575, scale: 0.3333 };
    case '/services/3':
      return { y: window.innerHeight / 2.2525, scale: 0.3333 };
    case '/projects':
      return { y: window.innerHeight / 2, scale: 1 };
    case '/contact':
      return { y: window.innerHeight / 1.5, scale: 1 };
    default:
      return { y: 0, scale: 1 };
  }
};
