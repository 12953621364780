import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContactButton } from 'components';
import Logo from './components/Logo';
import HamburgerMenu from './components/Hamburger';
import ParticlesBackground from './components/Particles';
import Menu from './components/Menu';
import useWindowSize from 'helpers/useWindowSize';
import { LayoutWrapper } from './Layout.style';

export const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();

  const toggle = () => {
    setIsOpen(() => !isOpen);
  };

  const isContactPage = pathname === '/contact';

  return (
    <>
      <LayoutWrapper>
        <ParticlesBackground isContactPage={isContactPage} />
        <HamburgerMenu
          toggle={toggle}
          open={isOpen}
          width={width}
          isContactPage={isContactPage}
        />
        <Logo
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          width={width}
          isContactPage={isContactPage}
        />
        <Menu
          open={isOpen}
          toggle={toggle}
          setIsOpen={setIsOpen}
          pathname={pathname}
        />
        <ContactButton setIsOpen={setIsOpen} isContactPage={isContactPage} />
        {children}
      </LayoutWrapper>
    </>
  );
};
