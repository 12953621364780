import React from 'react';

export const GitHubIcon = () => {
  return (
    <a target='_blank' rel='noopener noreferrer'>
      <svg
        className='icon github'
        role='img'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <title>GitHub</title>
        <path d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12' />
      </svg>
    </a>
  );
};

export const LinkedInIcon = () => {
  return (
    <a target='_blank' rel='noopener noreferrer'>
      <svg
        className='icon linkedin'
        role='img'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <title>LinkedIn</title>
        <path d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z' />
      </svg>
    </a>
  );
};

export const externalLink = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-external-link'
  >
    <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'></path>
    <polyline points='15 3 21 3 21 9'></polyline>
    <line x1='10' y1='14' x2='21' y2='3'></line>
  </svg>
);

export const projectsIcon = (
  <svg
    width='30'
    height='30'
    viewBox='0 0 24 24'
    stroke='#fff'
    fill='none'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-code'
    style={{ marginTop: '5px' }}
  >
    <polyline points='16 18 22 12 16 6'></polyline>
    <polyline points='8 6 2 12 8 18'></polyline>
  </svg>
);

export const benefitsIcon1 = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-user-check'
  >
    <path stroke='#fff' d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
    <circle stroke='#fff' cx='8.5' cy='7' r='4'></circle>
    <polyline stroke='#fff' points='17 11 19 13 23 9'></polyline>
  </svg>
);

export const benefitsIcon2 = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#fff'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-activity'
  >
    <polyline points='22 12 18 12 15 21 9 3 6 12 2 12'></polyline>
  </svg>
);

export const benefitsIcon3 = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#fff'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-codesandbox'
  >
    <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z'></path>
    <polyline points='7.5 4.21 12 6.81 16.5 4.21'></polyline>
    <polyline points='7.5 19.79 7.5 14.6 3 12'></polyline>
    <polyline points='21 12 16.5 14.6 16.5 19.79'></polyline>
    <polyline points='3.27 6.96 12 12.01 20.73 6.96'></polyline>
    <line x1='12' y1='22.08' x2='12' y2='12'></line>
  </svg>
);

export const serviceIcon1 = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#fff'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-smartphone'
  >
    <rect x='5' y='2' width='14' height='20' rx='2' ry='2'></rect>
    <line x1='12' y1='18' x2='12.01' y2='18'></line>
  </svg>
);

export const serviceIcon2 = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#fff'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-server'
  >
    <rect x='2' y='2' width='20' height='8' rx='2' ry='2'></rect>
    <rect x='2' y='14' width='20' height='8' rx='2' ry='2'></rect>
    <line x1='6' y1='6' x2='6.01' y2='6'></line>
    <line x1='6' y1='18' x2='6.01' y2='18'></line>
  </svg>
);

export const serviceIcon3 = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#fff'
    stroke-width='1'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-git-pull-request'
  >
    <circle cx='18' cy='18' r='3'></circle>
    <circle cx='6' cy='6' r='3'></circle>
    <path d='M13 6h3a2 2 0 0 1 2 2v7'></path>
    <line x1='6' y1='9' x2='6' y2='21'></line>
  </svg>
);
