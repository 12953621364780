import React from 'react';
import { motion } from 'framer-motion';
import { Content } from 'components';

import { externalLink, projectsIcon } from 'components';
import useWindowSize from 'helpers/useWindowSize';
import { contentVariants } from 'constants/animationVariants';
import projects from 'constants/projects';

import { ProjectsWrapper } from './Projects.style';

export const Projects = () => {
  const { width } = useWindowSize();

  const mobile = width < 768;

  return (
    <>
      <Content
        icon={projectsIcon}
        direction='left'
        title={<span className='glow'>Projects</span>}
      />
      <ProjectsWrapper>
        <motion.div
          className='contactContainer'
          variants={contentVariants}
          initial='out'
          animate='in'
          exit='exit'
        >
          <ul>
            {projects.map((p, i) => (
              <li key={i}>
                <a href={p.link}>
                  <span>
                    {p.title}
                    <sup>{externalLink}</sup>
                  </span>
                  {!mobile ? <p>{p.description}</p> : null}
                </a>
              </li>
            ))}
          </ul>
        </motion.div>
      </ProjectsWrapper>
    </>
  );
};
