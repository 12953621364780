import styled from 'styled-components';

export const HomeTextWrapper = styled.div`
  text-align: left;
  max-width: 600px;
  margin-left: 16.666666667%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;

  h1 {
    font-size: 50px;
  }

  img #particleimage {
    display: none;
  }

  @media (max-width: 767px) {
    top: 20%;
    transform: translateY(-20%);
    margin-left: 0;
    padding: 0px 30px;

    img {
      display: none;
    }

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
    }
  }

  img {
    height: 35px;
    width: auto;
    pointer-events: none;
  }

  .glow {
    text-shadow: 0 0 6px #f7f7f7;
  }
`;

export const ImageWrapper = styled.div`
  margin-left: ${({ direction }) => (direction === 'left' ? 40 : 10)}%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: ${({ direction }) => direction};
  width: 1000px;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;

    bottom: 10%;
    transform: translateY(10%);
  }
`;
