import React from 'react';

import { Content, benefitsIcon1 } from 'components';

export const Why1 = () => {
  const description =
    'Latest Insights on Neuromarketing and Behavioral Science will help you understand how your Users interact and how to leverage Design Best Practices to make your Application or Website stand out.';

  return (
    <>
      <Content
        icon={benefitsIcon1}
        direction='left'
        title={
          <span>
            Design <span className='glow'>User-Centered</span> Applications
          </span>
        }
        description={description}
        imgSrc='/assets/img/image-why1.png'
        particles={5}
      />
    </>
  );
};
