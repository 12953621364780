import React from 'react';
import { motion } from 'framer-motion';

import useWindowSize from 'helpers/useWindowSize';
import { ImageWrapper } from './ParticleImage.style';

export const ParticleImage = ({ direction, imgSrc, particles }) => {
  const imageRef = React.useRef(null);
  const { width } = useWindowSize();

  const mobile = width < 768;

  React.useEffect(() => {
    const particleImage = new window.NextParticle({
      image: imageRef.current,
      width: window.innerWidth,
      height: window.innerHeight,
      maxWidth: 1000,
      maxHeight: 1000,
      gravity: mobile ? 0.21 : 0.18,
      mouseForce: 0,
      noise: 5,
      particleGap: mobile ? 3 : particles ? particles : 3,
      initPosition: 'bottom',
      initDirection: 'bottom',
      fadePosition: 'top',
      fadeDirection: 'top',
      threeDimensional: true,
    });

    particleImage.state = 'stopped';

    particleImage._fadeOriginPosition = function (origin) {
      origin.x = Math.random() * this.width * 3 - this.width;
      origin.y = -Math.random() * this.height;
    };

    particleImage._fade = function () {
      this.speed = 0.15;

      if (this.origins) {
        for (let index = 0; index < this.origins.length; index++) {
          this._fadeOriginPosition(this.origins[index]);
        }
      }
    };

    window.addEventListener(
      'fadeEvent',
      () => {
        particleImage._fade();
      },
      { once: true }
    );

    return () => {
      particleImage.state = 'stopped';
    };
  }, [mobile, particles]);

  const variants = {
    in: {
      opacity: 1,
      transition: { duration: 1 },
    },
    out: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 1 },
    },
  };

  return (
    <ImageWrapper direction={direction}>
      <motion.div
        className='particlecontainer'
        variants={variants}
        initial='out'
        animate='in'
        exit='exit'
      >
        <img id='particleimage' src={imgSrc} alt='' ref={imageRef} />
      </motion.div>
    </ImageWrapper>
  );
};
