import React from 'react';
import { motion } from 'framer-motion';

import { HamburgerIconWrapper } from '../Layout.style';

const Hamburger = ({ toggle, open, width, isContactPage }) => {
  const openWidth = width > 767 ? 365 : 240;

  return (
    <motion.div
      animate={{ x: open ? openWidth : 0 }}
      transition={{ ease: [0.23, 1, 0.32, 1], duration: 1 }}
      style={{ zIndex: '9999' }}
    >
      <HamburgerIconWrapper onClick={toggle} isContactPage={isContactPage}>
        <span className='circle-wrapper'>
          <motion.svg
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            version='1.1'
            x='0px'
            y='0px'
            viewBox='0 0 38 38'
            style={{ zIndex: '999' }}
          >
            <circle cx='19' cy='19' r='18.5' fill='none'></circle>
          </motion.svg>
          {open ? (
            <span className='x' style={{ zIndex: '999' }}></span>
          ) : (
            <span className='icon-burger' style={{ zIndex: '999' }}>
              <span style={{ top: '0' }} className='icon-line'></span>
              <span style={{ top: '4px' }} className='icon-line'></span>
              <span style={{ bottom: '0' }} className='icon-line'></span>
            </span>
          )}
        </span>
      </HamburgerIconWrapper>
    </motion.div>
  );
};

export default Hamburger;
