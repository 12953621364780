export const contentVariants = {
  in: {
    opacity: 1,
    scaleY: 1,
    y: 0,
    transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
  },
  out: {
    opacity: 0,
    scaleY: 1.3,
    y: '10vh',
    transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
  },
  exit: {
    opacity: 0,
    scaleY: 1.3,
    y: '-10vh',
    transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
  },
};
