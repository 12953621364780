import React, { useEffect } from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { Layout } from 'container';
import runFadeAnimation from 'helpers/runFadeAnimation';
import routes from './routes';
import 'assets/style.css';

const Routes = () => {
  const location = useLocation();
  const history = useHistory();

  history.listen(() => {
    runFadeAnimation();
  });

  const handleScroll = (event) => {
    event.preventDefault();

    const i = routes.findIndex((i) => i.path === location.pathname);
    const prev = i - 1;
    const next = i + 1;

    if (event.deltaY >= 1 && i < routes.length - 1) {
      history.push(routes[next].path);
      window.removeEventListener('wheel', handleScroll);
    } else if (event.deltaY <= 0 && i > 0) {
      history.push(routes[prev].path);
      window.removeEventListener('wheel', handleScroll);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('wheel', handleScroll);
    }, 500);

    return () => window.removeEventListener('wheel', handleScroll);
  }, [handleScroll]);

  return (
    <Layout>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          {routes.map((route) => (
            <Route
              exact
              key={route.key}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </AnimatePresence>
    </Layout>
  );
};

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
};

setTimeout(() => {
  render(<App />, document.getElementById('root'));
}, 1000);
