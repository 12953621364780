import React from 'react';
import { Content, serviceIcon2 } from 'components';

export const Service2 = () => {
  const description =
    'Build your Application on a strong foundation by choosing the right technology stack, right from the beginning. Get yourself a performant, easy-to-maintain and reliable back-end solution, based on a scalable architecture.';

  return (
    <>
      <Content
        icon={serviceIcon2}
        direction='left'
        title={<span className='glow'>Backend Development</span>}
        description={description}
        imgSrc='/assets/img/image-service2.png'
        particles={5}
      />
    </>
  );
};
