import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  position: absolute;
  background-color: black;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: white;
`;

export const LogoWrapper = styled.div`
  @media (max-width: 767px) {
    margin-right: 25px;
    margin-top: 8px;
  }

  position: fixed;
  top: 0;
  right: 0;
  margin-right: 90px;
  margin-top: 65px;
  left: auto;
  padding: 15px;
  padding-right: 0;
  z-index: 200;
  overflow: hidden;
  transition-duration: 1s;

  .logo-inner {
    height: 25px;
    width: auto;
    cursor: pointer;
    transform: translateX(100px);
    transition-duration: 1s;
  }

  a {
    text-decoration: none;
  }

  h2 {
    color: #fff;
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.7));
    font-size: 30px;
    margin: 0;
    padding: 0;
  }

  .black {
    color: #000;
  }
`;

export const HamburgerIconWrapper = styled.a`
  @media (max-width: 767px) {
    margin-left: 20px;
    margin-top: 20px;
    z-index: 9999;
  }
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
  margin-left: 90px;
  margin-top: 70px;
  text-align: center;
  display: block;
  background: none;
  border-radius: 50%;
  padding: 0;
  height: 40px;
  width: 40px;
  text-decoration: none;
  outline: none;
  -webkit-appearance: none !important;

  .circle-wrapper {
    z-index: 9999;
    border: none !important;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;

    :after {
      z-index: 9999;
      content: '';
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }

  svg {
    z-index: 9999;
    stroke-dashoffset: 0;
    stroke-dasharray: 115;
    position: absolute;
    top: 0;
    left: 0;
    stroke: ${({ isContactPage }) =>
      !isContactPage ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
  }

  .icon-burger {
    z-index: 9999;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 14px;
    height: 9px;

    :after {
      z-index: 9999;
      content: '';
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }

  .icon-line {
    z-index: 9999;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    background: ${({ isContactPage }) => (!isContactPage ? '#fff' : '#000')};
  }

  .x {
    z-index: 9999;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 14px;
    height: 14px;
    text-align: center;

    :before {
      z-index: 9999;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${({ isContactPage }) => (!isContactPage ? '#fff' : '#000')};
      transform: rotate(45deg);
    }

    :after {
      z-index: 9999;
      transform: rotate(-45deg);
      background: ${({ isContactPage }) => (!isContactPage ? '#fff' : '#000')};
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
    }
  }
`;

export const MenuWrapper = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 999;

  .nav-content {
    z-index: 999;
    opacity: 1;
    padding-top: 10vh;
    padding-bottom: 10vh;
    top: 0;
    left: -365px;
    position: absolute;
    height: 100vh;
    padding-right: 10em;
    padding-left: 5em;
    background: #197a8c;

    @media (max-width: 767px) {
      padding-top: 5vh;
      padding-right: 5em;
      padding-left: 1em;
    }
  }

  .nav-items {
    z-index: 999;
    padding-top: 5vh;
    padding-bottom: 5vh;

    ul {
      z-index: 999;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  }

  .nav-list {
    z-index: 999;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    li {
      z-index: 999;
      padding-bottom: 4em;

      @media (max-width: 767px) {
        padding-bottom: 2.5em;
      }
    }

    a {
      transition-duration: 0.3s;
      z-index: 999;
      text-decoration: none;
      cursor: pointer;
      color: white;
      padding-bottom: 20px;
      padding-top: 20px;
      font-size: 2rem;

      @media (max-width: 767px) {
        font-size: 20px;
      }

      :hover {
        transition-duration: 0.3s;
        filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.7));
      }
    }
    sup {
      font-size: 1rem;
      top: -1.5em;
    }
  }

  .social-list {
    margin-right: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    li {
      @media (max-width: 768px) {
        padding-bottom: 20px;
      }
      padding-bottom: 50px;
      display: inline-block;
    }

    .icon {
      transition-duration: 0.3s;
      width: 25px;
      cursor: pointer;
    }

    .github {
      fill: white;
      margin-right: 15px;

      :hover {
        transition-duration: 0.3s;
        fill: #181717;
      }
    }

    .linkedin {
      fill: white;
      margin-right: 15px;

      :hover {
        transition-duration: 0.3s;
        fill: #0077b5;
      }
    }

    .upwork {
      fill: white;
      :hover {
        transition-duration: 0.3s;
        fill: #6fda44;
      }
    }
  }

  .language {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    ul {
      display: inline-block;
    }

    li {
      display: inline-block;
      margin-right: 10px;
      transition-duration: 0.3s;
    }
    .lang {
      transition-duration: 0.3s;
      cursor: pointer;

      :hover {
        transition-duration: 0.3s;
        filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.7));
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
`;

export const ParticleBgWrapper = styled.div`
  .particlesBgWrapper {
    position: absolute;
    width: 100%;
    height: 2800px;

    @media (max-width: 767px) {
      height: 100%;
    }
    z-index: 0;
  }
`;
