import React from 'react';
import { Content, serviceIcon3 } from 'components';

export const Service3 = () => {
  const description =
    'Complete Web Apps using modern Tech Stacks. Build performant web applications and deliver fast updates thanks to continuous integration and automation. Provide an exceptional user experience by combining proven development techniques and innovations.';

  return (
    <>
      <Content
        icon={serviceIcon3}
        direction='left'
        title={<span className='glow'>Fullstack Development</span>}
        description={description}
        imgSrc='/assets/img/image-service3.png'
        particles={5}
      />
    </>
  );
};
