import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin-left: ${({ direction }) =>
    direction === 'left' ? 16.666666667 : 49.8}%;
  width: 33.33333333333333%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: ${({ direction }) => direction};
  z-index: 20;

  h1 {
    font-size: 38px;
  }

  img {
    display: none;
  }

  @media (max-width: 767px) {
    top: 20%;
    transform: translateY(-20%);
    margin-left: 0;
    width: auto;
    padding: 0px 30px;

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
    }
  }

  .glow {
    text-shadow: 0 0 6px #f7f7f7;
  }
`;

export const IconWrapper = styled.div`
  margin-left: ${({ direction }) => (direction === 'left' ? 0 : 'auto')};
  position: relative;
  pointer-events: none;
  width: 100px;
  height: 100px;

  .circle {
    position: absolute;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
`;

export const InnerIcon = styled.div`
  text-align: center;
  padding-top: 30px;
`;
