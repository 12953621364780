import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import useWindowSize from 'helpers/useWindowSize';
import menu from 'constants/menu';
import { routeSwitcher, currentPosition } from './navHelper';

import {
  Timeline,
  Navline,
  ListWrapper,
  PointWrapper,
  MobileNavWrapper,
} from './Navigation.style';

import ActiveLine from './ActiveLine';

export const Navigation = () => {
  const history = useHistory();
  const { width } = useWindowSize();
  const [currentLocation, setCurrentLocation] = useState('');
  const [position, setPosition] = useState({});

  const currentRoute = history.location.pathname;
  const isContactPage = currentRoute === '/contact';

  const activeClass = (path) => {
    const split = path.split('/');
    const current = currentLocation.split('/');

    if (split[1] === current[1]) {
      return 'active';
    }

    return '';
  };

  useEffect(() => {
    setCurrentLocation(currentRoute);
    const axis = currentPosition(currentRoute);
    setPosition({ ...axis });
  }, [currentRoute]);

  return (
    <>
      {width > 767 ? (
        <Timeline>
          <Navline isContactPage={isContactPage}></Navline>
          <ListWrapper isContactPage={isContactPage}>
            <ul>
              {menu.map((item, index) => (
                <li className={`moveableList ${activeClass(item.route)}`}>
                  <Link to={item.route}>
                    <span className='upperNum'>0</span>
                    <span className='lowerNum'>{index}</span>
                    <span className='sectionName'>{item.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <PointWrapper isContactPage={isContactPage}>
              <ActiveLine position={position} />
              {menu.map((item) =>
                item.hasChildren ? (
                  <ul className='navlist'>
                    <li className='listitem'>
                      <span className='point'></span>
                    </li>
                    <li className='listitem'>
                      <span className='point'></span>
                    </li>
                    <li className='listitem'>
                      <span className='point'></span>
                    </li>
                  </ul>
                ) : (
                  <ul className='navlist'></ul>
                )
              )}

              <ul className='navlist'>
                <span className='plus'></span>
              </ul>
            </PointWrapper>
          </ListWrapper>
        </Timeline>
      ) : (
        <>
          {!isContactPage ? (
            <MobileNavWrapper>
              <div className='mobile-content'>
                <div className='mobile-inner'>
                  <Link to='/contact'>
                    <svg
                      version='1.1'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                    >
                      <path
                        fill='#fff'
                        d='M0.5 19c-0.225 0-0.422-0.15-0.482-0.367s0.032-0.447 0.225-0.562c1.691-1.014 2.392-2.489 2.641-3.179-1.838-1.407-2.884-3.354-2.884-5.392 0-1.029 0.258-2.026 0.768-2.964 0.486-0.894 1.18-1.695 2.061-2.381 1.787-1.39 4.156-2.156 6.671-2.156s4.884 0.766 6.671 2.156c0.881 0.685 1.575 1.486 2.061 2.381 0.51 0.937 0.768 1.934 0.768 2.964s-0.258 2.026-0.768 2.964c-0.486 0.894-1.18 1.695-2.061 2.381-1.787 1.39-4.156 2.156-6.671 2.156-1.033 0-2.047-0.129-3.016-0.385-0.429 0.286-1.231 0.793-2.189 1.27-1.488 0.74-2.764 1.115-3.794 1.115zM9.5 3c-4.687 0-8.5 2.916-8.5 6.5 0 1.815 1.005 3.562 2.756 4.792 0.172 0.121 0.25 0.336 0.196 0.539-0.117 0.436-0.515 1.633-1.58 2.788 1.302-0.456 2.704-1.247 3.739-1.959 0.123-0.085 0.277-0.11 0.421-0.069 0.948 0.271 1.947 0.409 2.968 0.409 4.687 0 8.5-2.916 8.5-6.5s-3.813-6.5-8.5-6.5z'
                      ></path>
                    </svg>
                  </Link>
                </div>
                <div className='mobile-arrows'>
                  <Link
                    className='page-prev'
                    to={routeSwitcher(currentRoute).prev}
                  >
                    <svg
                      version='1.1'
                      width='30'
                      height='30'
                      viewBox='0 0 20 20'
                    >
                      <path
                        fill='rgba(255,255,255,0.2)'
                        d='M2.782 3.782c-1.794 1.794-2.782 4.18-2.782 6.718s0.988 4.923 2.782 6.718 4.18 2.782 6.718 2.782 4.923-0.988 6.718-2.782 2.782-4.18 2.782-6.717-0.988-4.923-2.782-6.718-4.18-2.782-6.718-2.782-4.923 0.988-6.718 2.782zM18 10.5c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5c0-4.687 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5z'
                      ></path>
                      <path
                        fill='rgba(255,255,255,1)'
                        d='M15 12c0 0.128-0.049 0.256-0.146 0.354-0.195 0.195-0.512 0.195-0.707 0l-4.646-4.646-4.646 4.646c-0.195 0.195-0.512 0.195-0.707 0s-0.195-0.512 0-0.707l5-5c0.195-0.195 0.512-0.195 0.707 0l5 5c0.098 0.098 0.146 0.226 0.146 0.354z'
                      ></path>
                    </svg>
                  </Link>
                  <Link
                    className='page-next'
                    to={routeSwitcher(currentRoute).next}
                  >
                    <svg
                      version='1.1'
                      width='30'
                      height='30'
                      viewBox='0 0 20 20'
                    >
                      <path
                        fill='rgba(255,255,255,0.2)'
                        d='M16.218 17.218c1.794-1.794 2.782-4.18 2.782-6.718s-0.988-4.923-2.782-6.717-4.18-2.782-6.718-2.782-4.923 0.988-6.718 2.782-2.782 4.18-2.782 6.717 0.988 4.923 2.782 6.718 4.18 2.782 6.718 2.782 4.923-0.988 6.718-2.782zM1 10.5c0-4.687 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5z'
                      ></path>
                      <path
                        fill='rgba(255,255,255,1)'
                        d='M4 9c0-0.128 0.049-0.256 0.146-0.354 0.195-0.195 0.512-0.195 0.707 0l4.646 4.646 4.646-4.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-5 5c-0.195 0.195-0.512 0.195-0.707 0l-5-5c-0.098-0.098-0.146-0.226-0.146-0.354z'
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </MobileNavWrapper>
          ) : null}
        </>
      )}
    </>
  );
};
