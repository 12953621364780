import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import useWindowSize from 'helpers/useWindowSize';
import { ButtonWrapper } from './ContactButton.style';

export const ContactButton = ({ setIsOpen, isContactPage }) => {
  const { width } = useWindowSize();

  const runFadeAnimation = () => {
    const fadeEvent = new Event('fadeEvent');
    window.dispatchEvent(fadeEvent);
  };

  return (
    <>
      {width > 767 && !isContactPage ? (
        <ButtonWrapper>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ cubicBezier: [0.23, 1, 0.32, 1], duration: 1 }}
          >
            <Link
              to='/contact'
              onClick={() => {
                setIsOpen(false);
                runFadeAnimation();
              }}
            >
              <span>Contact</span>
            </Link>
          </motion.div>
        </ButtonWrapper>
      ) : null}
    </>
  );
};
