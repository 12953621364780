import {
  Home,
  Contact,
  Projects,
  Service1,
  Service2,
  Service3,
  Why1,
  Why2,
  Why3,
} from 'container';

const routes = [
  {
    key: 1,
    path: '/',
    component: Home,
  },
  {
    key: 2,
    path: '/benefits',
    component: Why1,
  },
  {
    key: 3,
    path: '/benefits/2',
    component: Why2,
  },
  {
    key: 4,
    path: '/benefits/3',
    component: Why3,
  },
  {
    key: 5,
    path: '/services',
    component: Service1,
  },
  {
    key: 6,
    path: '/services/2',
    component: Service2,
  },
  {
    key: 7,
    path: '/services/3',
    component: Service3,
  },
  {
    key: 8,
    path: '/projects',
    component: Projects,
  },
  {
    key: 9,
    path: '/contact',
    component: Contact,
  },
];

export default routes;
