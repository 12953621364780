const colorSwitcher = (currentPath, isMobile) => {
  switch (currentPath) {
    case '/':
      return {
        color: 'linear-gradient(45deg, #073f84 30%, #1a0b22 110%)',
        yAxis: 0,
      };
    case '/benefits':
      return {
        color: 'linear-gradient(45deg, #073f84 20%, #1a0b22 70%)',
        yAxis: isMobile ? 0 : -200,
      };
    case '/benefits/2':
      return {
        color: 'linear-gradient(45deg, #182848 30%, #0f7199 90%)',
        yAxis: isMobile ? 0 : -400,
      };
    case '/benefits/3':
      return {
        color: 'linear-gradient(45deg, #6a097d 5%, #00005c 90%)',
        yAxis: isMobile ? 0 : -600,
      };
    case '/services':
      return {
        color:
          'linear-gradient(45deg, rgba(60,55,106,1) 20%, rgba(23,20,69,1) 70%)',
        yAxis: isMobile ? 0 : -800,
      };
    case '/services/2':
      return {
        color:
          'linear-gradient(45deg, rgba(60,55,106,1) 20%, rgba(23,20,69,1) 70%)',
        yAxis: isMobile ? 0 : -1000,
      };
    case '/services/3':
      return {
        color:
          'linear-gradient(45deg, rgba(60,55,106,1) 20%, rgba(23,20,69,1) 70%)',
        yAxis: isMobile ? 0 : -1200,
      };
    case '/projects':
      return {
        color: isMobile
          ? 'linear-gradient(45deg, rgba(8,65,91,1) 20%, rgba(19,126,57,1) 120% )'
          : 'linear-gradient(45deg, rgba(8,65,91,1) 10%, rgba(19,126,57,1) 130% )',
        yAxis: isMobile ? 0 : -1400,
      };
    case '/contact':
      return {
        color: 'linear-gradient(45deg, #f2f2f2 10%, #fff 90%)',
        yAxis: isMobile ? 0 : -1600,
      };
    default:
      return {
        color: 'linear-gradient(45deg, #073f84 30%, #1a0b22 110%)',
        yAxis: 0,
      };
  }
};

export default colorSwitcher;
