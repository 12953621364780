import React from 'react';
import { motion } from 'framer-motion';

import { ParticleImage } from 'components';
import useWindowSize from 'helpers/useWindowSize';
import { contentVariants } from 'constants/animationVariants';

import Icon from './Icon';
import { ContentWrapper } from './Content.style';

export const Content = ({
  direction,
  icon,
  title,
  description,
  imgSrc,
  particles,
}) => {
  const { width } = useWindowSize();

  return (
    <>
      <ContentWrapper direction={direction}>
        {width > 767 ? <Icon direction={direction} icon={icon} /> : null}
        <motion.div
          initial='out'
          animate='in'
          exit='exit'
          variants={contentVariants}
        >
          <h1>{title}</h1>
          <p style={{ marginBottom: '25px' }}>{description}</p>
        </motion.div>
      </ContentWrapper>
      {imgSrc ? (
        <ParticleImage
          direction={direction}
          imgSrc={imgSrc}
          particles={particles}
        />
      ) : null}
    </>
  );
};
