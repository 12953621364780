import styled from 'styled-components';

export const ProjectsWrapper = styled.div`
  position: absolute;
  margin-left: 40%;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;

  a {
    transition-duration: 0.3s;
    text-decoration: none;
    cursor: pointer;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;

    font-size: 2.5em;
    z-index: 999;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    :hover {
      transition-duration: 0.3s;
      filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.7));
    }
  }

  p {
    color: rgba(255, 255, 255, 0.6);
  }

  ul {
    z-index: 999;
  }

  li {
    list-style: none;
    padding-bottom: 2em;
    padding-right: 30px;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;

    top: 65%;
    transform: translateY(-65%);
  }
`;
