import React from 'react';
import { Content, serviceIcon1 } from 'components';

export const Service1 = () => {
  const description =
    'Your web application crafted from front to back - every aspect considered. Implementing beautiful, responsive user interfaces that are highly-interactive and give users a native-like experience.';

  return (
    <>
      <Content
        icon={serviceIcon1}
        direction='left'
        title={<span className='glow'>Frontend Development</span>}
        description={description}
        imgSrc='/assets/img/image-service1.png'
        particles={5}
      />
    </>
  );
};
