import React from 'react';
import { motion } from 'framer-motion';

import { LinkedInIcon, GitHubIcon } from 'components';
import { contentVariants } from 'constants/animationVariants';
import useWindowSize from 'helpers/useWindowSize';

import {
  ContactWrapper,
  ContactParticleWrapper,
  ContactCol,
} from './Contact.style';

export const Contact = () => {
  const imageRef = React.useRef(null);
  const { width } = useWindowSize();

  const mobile = width < 768;

  React.useEffect(() => {
    const particleImage = new window.NextParticle({
      image: imageRef.current,
      width: window.innerWidth,
      height: window.innerHeight,
      gravity: 0.2,
      mouseForce: 0,
      noise: 5,
      particleGap: mobile ? 3 : 5,
      initPosition: 'bottom',
      initDirection: 'bottom',
      fadePosition: 'top',
      fadeDirection: 'left',
    });

    particleImage.state = 'stopped';

    return () => {
      particleImage.state = 'stopped';
    };
  }, [mobile]);

  const variants = {
    in: {
      opacity: 1,
      transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
    },
    out: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: { cubicBezier: [0.23, 1, 0.32, 1], duration: 1 },
    },
  };

  return (
    <>
      <ContactWrapper>
        <motion.div
          className='contactContainer'
          variants={contentVariants}
          initial='out'
          animate='in'
          exit='exit'
        >
          <ContactCol>
            <h1>Links</h1>
            <p>You can contact me by clicking on one of the Icons below.</p>
            <ul className='social-list'>
              <li>
                <GitHubIcon />
              </li>
              <li>
                <LinkedInIcon />
              </li>
            </ul>
          </ContactCol>
          <ContactCol>
            <h1 className='right'>Email</h1>
            <p className='right'>
              Or send me and Email and we will be in touch
              <br /> at{' '}
              <a style={{ color: '#197a8c' }} href='#'>
                example@email.com
              </a>
            </p>
          </ContactCol>
        </motion.div>
      </ContactWrapper>
      <ContactParticleWrapper>
        <motion.div
          className='particlecontainer'
          variants={variants}
          initial='out'
          animate='in'
          exit='exit'
        >
          <img
            id='particleimage'
            src='/assets/img/image-contact.png'
            ref={imageRef}
            alt=''
          />
        </motion.div>
      </ContactParticleWrapper>
    </>
  );
};
