import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: block;
  bottom: 0;
  right: 0;
  position: fixed;
  margin-right: 90px;
  margin-bottom: 70px;
  z-index: 20;

  a {
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
      color 0.8s cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
    font-size: 0.8125rem;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    cursor: pointer;
    background: #197a8c;
    border: none;
    outline: none;
    text-decoration: none;
    position: relative;
  }

  a:hover {
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
      color 0.8s cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);

    color: #197a8c;
    background: #fff;
  }
`;
